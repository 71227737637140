<template>
  <v-container>
    <h2 class="ma-2">Start, Mål och Hantera lag</h2>
    <v-data-table
    :headers="headers"
    :items="teams"
    :search="search"
    sort-by="t_start_position"
    class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Alla lag</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Sök"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.t_ts_checked_in="{ item }">
          <v-chip class="ma-1" :color="item.t_ts_checked_in === 'Nej' ? 'red' : 'green'" dark>
            {{ item.t_ts_checked_in }}
        </v-chip>
    </template>
      <template v-slot:item.t_reglemente="{ item }">
            <v-chip class="ma-1" :color="item.t_reglemente === 'Nej' ? 'red' : 'green'" dark>
              {{ item.t_reglemente }}
          </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          class="my-1 mx-1"
          @click="start(item)"
          color="secondary"
        >
          Start
        </v-btn>
        <v-btn
          small
          class="my-1 mx-1"
          @click="finish(item)"
          color="secondary"
        >
          Mål
        </v-btn>
        <v-btn
          small
          class="my-1 mx-1"
          @click="checkIn(item)"
          color="primary"
        >
          Checka in
        </v-btn>
        <v-btn
          small
          class="my-1 mx-1"
          dark
          @click="reglemente(item)"
          color="grey"
        >
          Reglemente
        </v-btn>
        <v-btn
          small
          class="my-1 mx-1"
          dark
          @click="reset(item)"
          color="orange"
        >
          Återställ
        </v-btn>
        <v-btn
        small
        class="my-1 mx-1"
        dark
        @click="diska(item)"
        color="red"
      >
        Diska
      </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Ladda data
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import {notify} from '../../utils/notification'

export default {
  name: 'Steel',
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Startnr',
        align: 'start',
        sortable: false,
        value: 't_start_position',
      },
      { text: 'Lagnamn', value: 't_name' },
      { text: 'Mejl', value: 't_email' },
      { text: 'Kod', value: 't_token' },
      { text: 'Incheckade', value: 't_ts_checked_in'},
      { text: 'Inlämnat reglemente', value: 't_reglemente'},
      { text: 'Starttid', value: 't_ts_start' },
      { text: 'Måltid', value: 't_ts_finish' },
      { text: 'Handling', value: 'actions', sortable: false },
    ],
    teams: [],
  }),

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions(["getAllTeams", "startTeam", "finishTeam", "reglementeTeam", "checkInTeam", "resetTeam", "diskaTeam", "clearNotifications"]),
    async initialize () {
      const teams = await this.getAllTeams()
      //Filter teams
      teams.forEach((team) => {
        team.t_reglemente = team.t_reglemente ? "Ja" : "Nej"
        team.t_ts_checked_in = team.t_ts_checked_in ? new Date(team.t_ts_checked_in).toLocaleTimeString() : "Nej"
        team.t_ts_start = team.t_ts_start ? new Date(team.t_ts_start).toLocaleTimeString(): ""
        team.t_ts_finish = team.t_ts_finish ? new Date(team.t_ts_finish).toLocaleTimeString() : ""
      })
      // Reset
      this.teams = teams
    },
    async userConfirmAction (action, team) {
      return confirm(`Är du säker på att du vill göra handling ${action} på laget ${team.t_name}?`)
    },
    async start(team) {
      if (await this.userConfirmAction("start", team)) {
        const parsedTeam = JSON.parse(JSON.stringify(team))
        await this.startTeam(parsedTeam.t_start_position)

        // Notification
        const success = notify(this)
        if (success) {
          this.initialize()      
        }
      }
    },
    async finish(team) {
      if (await this.userConfirmAction("mål", team)) {
        const parsedTeam = JSON.parse(JSON.stringify(team))
        await this.finishTeam(parsedTeam.t_start_position)

        // Notification
        const success = notify(this)
        if (success) {
          this.initialize()      
        }
      }
    },
    async reglemente(team) {
      if (await this.userConfirmAction("reglemente", team)) {
        const parsedTeam = JSON.parse(JSON.stringify(team))
        await this.reglementeTeam(parsedTeam.t_start_position)

        // Notification
        const success = notify(this)
        if (success) {
          this.initialize()      
        }
      }
    },
    async checkIn(team) {
      if (await this.userConfirmAction("checka in", team)) {
        const parsedTeam = JSON.parse(JSON.stringify(team))
        await this.checkInTeam(parsedTeam.t_start_position)

        // Notification
        const success = notify(this)
        if (success) {
          this.initialize()      
        }
      }
    },
    async reset(team) {
      if (await this.userConfirmAction("återställ", team)) {
        const parsedTeam = JSON.parse(JSON.stringify(team))
        await this.resetTeam(parsedTeam.t_start_position)
        // Notification
        const success = notify(this)
        if (success) {
          this.initialize()      
        }
      }
    },
    async diska(team) {
      if (await this.userConfirmAction("Diska", team)) {
        const parsedTeam = JSON.parse(JSON.stringify(team))
        await this.diskaTeam(parsedTeam.t_start_position)

        // Notification
        const success = notify(this)
        if (success) {
          this.initialize()      
        }
      }
    }
  },
  computed: mapGetters(["notification"])
}
</script>

<style>

</style>